import React from 'react';
import './Loader.css';
import { Loader, Placeholder } from 'rsuite';

const Loading = () => (
  <div>
    <Placeholder.Paragraph rows={30} size={'md'}/>
    <Loader backdrop content="loading..." vertical />
  </div>
);

export default Loading;