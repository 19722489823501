import React from "react";
import { useState,useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";
import Http from "../../BaseUrl/Http";
import { ToastContainer, toast } from "react-toastify";
import fileImage from "../../Image/folder.png";

function ClientFileUpload() {

 
  const nav=useNavigate();
  let {id}=useParams();
  console.log("id"+id);
  
  const [errorMessage,setErrorMessage]=useState([]);
  const [uploadFile,setUploadFile]=useState({
    uploadF:'',
    name:'',
    date:'',
    client:localStorage.getItem("clientId"),
    visaPatner:localStorage.getItem("visaPatnerId")
  })

  const [DataListClient, setDataListclient] = useState([]);
  const [DataListVisa, setDataListVisa] = useState([]);
  useEffect(()=>{
    fetchData();
  },[])

  const fetchData =()=>{

    const formData=new FormData();
    formData.append('id', id);
    Http.post('/getDocumentClient',formData).then(res=>{
      console.log(res);
      setDataListclient(res.data.client);
      setDataListVisa(res.data.visaPartner);
    })
    // setFile(''); // clear file input
    // setName(''); // clear name input
    // setDate(''); // clear date input
  }
  console.log('data'+DataListClient);
  

  const inputControl=(e)=>{
    // setFile(e.target.value)
    if (e.target.type === 'file') {
        setUploadFile({...uploadFile, uploadF: e.target.files[0] });
      } else {
        setUploadFile({...uploadFile, [e.target.name]: e.target.value });
      }
      console.log(uploadFile);
    
  }

  const deleteFun=(documId)=>{
    console.log("pass");
    
    const formData=new FormData();
    formData.append('id', documId);
  
      Http.post('/deleteDocument',formData).then(res=>{
        console.log(res);
        toast.success("Fle Delete Successfully");
      fetchData()


    }).catch((error) => {
     if(error.response.status==400){
      console.log(error.response);
     }
     return error;
  })
    
  }

  const saveData=(e)=>{
    e.preventDefault();
    const formData=new FormData();
    formData.append('id', id);
    formData.append('uploadFile', uploadFile.uploadF);
    formData.append('name', uploadFile.name);
    formData.append('date', uploadFile.date);
    formData.append('client', uploadFile.client);
    formData.append('visaPatner', uploadFile.visaPatner);

    const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
  
      Http.post('/fileUpload',formData,config).then(res=>{
      fetchData();
      uploadFile.uploadF='';
      uploadFile.name='';
      uploadFile.date='';
      document.getElementById("fileBlank").value ='';
      document.getElementById("nameBlank").value ='';
      document.getElementById("dateBlank").value ='';
      toast.success("File Add Successfully");

    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        setErrorMessage(error.response.data.message);
      }
      // toast.success("File Add Successfully");
      return error;
    })
}
    return (
     <>
   <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-2">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">File Upload</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">File information</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <form  onSubmit={saveData}>
            <div className="row">
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">file </label>
              <input type="file" className="form-control" accept="application/pdf,image/png,image/jpg" id="fileBlank" onChange={inputControl} placeholder="Enter agency name" />
              <span className="text-danger">{errorMessage.uploadFile}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">File Name </label>
              <input type="text" className="form-control" onChange={inputControl}  id="nameBlank"  name="name" placeholder="Enter first name" />
              <span className="text-danger">{errorMessage.name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Expire Date </label>
              <input type="date" className="form-control" onChange={inputControl}  id="dateBlank"  name="date" placeholder="Enter Surname" />
              <span className="text-danger">{errorMessage.data}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-12">
                {/* Form */}
                <button type="submit" class="btn btn-primary">Save</button>
                {/* End Form */}
              </div>
            </div>
            </form>
            {/* End Quill */}
          </div>
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>
    </div>
    {/* End Row */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          <div className="card-body">
             {/* Gallery */}
           <h6>Client</h6>
            <div id="fancyboxGallery" className="js-fancybox row justify-content-sm-center gx-3">
  <ToastContainer/>
  {DataListClient && DataListClient.map((item,index)=>(
                    <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                    {/* Card */}
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row col-divider text-center">
                          <a href={item.document} target="_blank">
                        <img className="card-img-top" style={{width: 50}} src={fileImage} alt="file" />
                         </a>
                          <div className="col">
                            Name:{item.name} <br />
                            Date:{item.expiry_date} <br />
                            <a className="text-danger"   onClick={()=>{deleteFun(item.id)}}>
                              <i className="bi-trash" />
                            </a>
                          </div>
                          {/* End Col */}
                        </div>
                        {/* End Row */}
                      </div>
                      {/* End Col */}
                    </div>
                    {/* End Card */}
                  </div>
                ))}
             </div>

                <h6>Visa Partner</h6>
             <div id="fancyboxGallery" className="js-fancybox row justify-content-sm-center gx-3">
  {DataListVisa && DataListVisa.map((item,index)=>(
                    <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                    {/* Card */}
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row col-divider text-center">
                          <a href={item.document} target="_blank">
                        <img className="card-img-top" style={{width: 50}} src={fileImage} alt="file" />
                         </a>
                          <div className="col">
                            Name:{item.name} <br />
                            Date:{item.expiry_date} <br />
                            {/* <a className="text-danger"   onClick={()=>{deleteFun(item.id)}}>
                              <i className="bi-trash" />
                            </a> */}
                          </div>
                          {/* End Col */}
                        </div>
                        {/* End Row */}
                      </div>
                      {/* End Col */}
                    </div>
                    {/* End Card */}
                  </div>
                ))}
           </div>
          </div>
          
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>

    </div>
 
  </div>
</div>
     </>
    );
  }
  
export default ClientFileUpload;
  