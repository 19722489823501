import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Home from '../Pages/Home'
import CountryList from '../Pages/Country/CountryList'
import AddCountry from '../Pages/Country/AddCountry'
import EditCountry from '../Pages/Country/editCountry'
import AddStaff from '../Pages/Staff/addStaff'
import StaffList from '../Pages/Staff/listStaff'
import ClientList from '../Pages/Client/ClientList' 
import AddClient from '../Pages/Client/AddClient'
import AddVisaPatner from '../Pages/VisaPatner/AddVisaPatner'   
import VisaPatnerList from '../Pages/VisaPatner/VisaPatnerList'
import AddApplication from '../Pages/Application/AddApplication'
import ApplicationList from '../Pages/Application/ApplicationList'
import Login from '../Pages/Login/Login'
import StaffHome from '../Pages/StaffUser/StaffHome'
import ClientHome from '../Pages/ClientUser/ClientHome'
import VisaPatnerHome from '../Pages/VisaPatnerUser/VisaPatnerUser'
import EditStaff from '../Pages/Staff/editStaff'
import EditClient from '../Pages/Client/EditClient'
import EditVisaPatner from '../Pages/VisaPatner/EditVisaPatner'
import EditApplication from '../Pages/Application/EditApplication'
import ShowApplication from '../Pages/Application/ShowApplication'
import ShowStaff from '../Pages/Staff/showStaff'
import ShowClient from '../Pages/Client/ShowClient'
import ShowVizaPatner from '../Pages/VisaPatner/showVisaPatner'
import VisaApplicatioList from '../Pages/VisaPatnerUser/VisaApplicationList'
import VisaApplicationShow from '../Pages/VisaPatnerUser/VisaApplicationShow'
import FileUpload from '../Pages/FileUpload/fileUpload'
import ClientApplicationList from '../Pages/ClientUser/ClientApplication'
import ClientApplicationShow from '../Pages/ClientUser/clientApplicationShow'
import ClientAddApplication from '../Pages/ClientUser/ClientApplicationAdd'
import ClientApplicationEdit from '../Pages/ClientUser/ClientApplicationEdit'
import StatusList from '../Pages/Status/StatusList'
import AddStatus from '../Pages/Status/AddStatus'
import EditStatus from '../Pages/Status/EditStatus'
import ExpireApplicationAdmin from '../Pages/ExpireApplication/AdminExpireApplicationList'
import ClientExpireApplicationList from '../Pages/ExpireApplication/ClientExpireApplicationList'
import VisaPartnerExpire from '../Pages/ExpireApplication/VisaPartnerExpire'
import ClientFileUpload from '../Pages/FileUpload/clientFileUpload'
import VisaFileUpload from '../Pages/FileUpload/VisaFileUpload'
import BannerList from '../Pages/Banner/bannerList'
import AddBanner from '../Pages/Banner/addBanner'
import EditBanner from '../Pages/Banner/edit.blade'


const router = createBrowserRouter([
    {
        path : "/",
        element :<Login/>,
    },
    {
        path : "/admin",
        element :<App/>,
        children : [
            {
                path : "",
                element :<Home/>
            },
            {
                path : "/admin/statusList",
                element :<StatusList/>
            },
            {
                path : "/admin/bannerList",
                element :<BannerList/>
            },
            {
                path : "/admin/addBanner",
                element :<AddBanner/>
            },
            {
                path : "/admin/editBanner/:id/edit",
                element :<EditBanner/>
            },
            
            {
                path : "/admin/addStatus",
                element :<AddStatus/>
            },
            {
                path : "/admin/editStatus/:id/edit",
                element :<EditStatus/>
            },
            {
                path : "/admin/CountryList",
                element :<CountryList/>
            },
            {
                path : "/admin/addCountry",
                element :<AddCountry/>
            },
            {
                path : "/admin/editCountry/:id/edit",
                element :<EditCountry/>
            },
            {
                path : "/admin/staffList",
                element :<StaffList/>
            },
            {
                path : "/admin/showStaff/:id/show",
                element :<ShowStaff/>
            },
            {
                path : "/admin/addStaff",
                element :<AddStaff/>
            },
            {
                path : "/admin/editClient/:id/edit",
                element :<EditClient/>
            },
            {
                path : "/admin/addClient",
                element :<AddClient/>
            },
            {
                path : "/admin/showClient/:id/show",
                element :<ShowClient/>
            },
            {
                path : "/admin/clientList",
                element :<ClientList/>
            },
            {
                path : "/admin/editStaff/:id/edit",
                element :<EditStaff/>
            },
            {
                path : "/admin/addVisaPatner",
                element :<AddVisaPatner/>
            },
            {
                path : "/admin/editVisaPatner/:id/edit",
                element :<EditVisaPatner/>
            },
            {
                path : "/admin/visaPatnerList",
                element :<VisaPatnerList/>
            },
            {
                path : "/admin/ShowVizaPatner/:id/show",
                element :<ShowVizaPatner/>
            },
            {
                path : "/admin/addApplication",
                element :<AddApplication/>
            },
            {
                path : "/admin/editApplication/:id/edit",
                element :<EditApplication/>
            },
            {
                path : "/admin/showApplication/:id/show",
                element :<ShowApplication/>
            },
            {
                path : "/admin/applicationList",
                element :<ApplicationList/>
            },
            {
                path : "/admin/application/:id/fileUpload",
                element :<FileUpload/>
            },
            {
                path : "/admin/expireApplication",
                element :<ExpireApplicationAdmin/>
            },
           
        ]
      
    },{
        path : "/staff/home/",
        element :<App/>,
        children : [
            {
                path : "",
                element :<StaffHome/>
            },
            {
                path : "/staff/home/DocumentExpire",
                element :<ClientExpireApplicationList/>
            },
        ]
    }
    ,{
        path : "/client/home/",
        element :<App/>,
        children : [
            {
                path : "",
                element :<ClientHome/>
            },
            {
                path : "/client/home/add",
                element :<ClientAddApplication/>
            },
            {
                path : "/client/home/list",
                element :<ClientApplicationList/>
            },
            {
                path : "/client/home/:id/show",
                element :<ClientApplicationShow/>
            },
            {
                path : "/client/home/application/:id/edit",
                element :<ClientApplicationEdit/>
            },
            {
                path : "/client/home/:id/fileUpload",
                element :<ClientFileUpload/>
            },
        ]
    }
    ,{
        path : "/visaPatner/home/",
        element :<App/>,
        children : [
            {
                path : "",
                element :<VisaPatnerHome/>
            },
            {
                path : "/visaPatner/home/list",
                element :<VisaApplicatioList/>
            },
            {
                path : "/visaPatner/home/list/:id/show",
                element :<VisaApplicationShow/>
            },
            {
                path : "/visaPatner/home/:id/fileUpload",
                element :<VisaFileUpload/>
            },
            {
                path : "/visaPatner/home/applicationExpire",
                element :<VisaPartnerExpire/>
            },
        ]
    }
])

export default router
