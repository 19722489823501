import React from "react";
import axios from "axios";
import Http from "../../BaseUrl/Http";
import { useEffect,useState } from "react";
import { Link,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function VisaPatnerList() {

  const [searcData, setSearchData] = useState({
    name: "",
    status: "",
  });

  const [pagination, setPagination] = useState({
    total: "",
    show: "",
    links: "",
  });

  function submitSearch(e){
    const data = {
      agency_name: searcData.name,
      status: searcData.status,
    };
    e.preventDefault();
    const queryParams = new URLSearchParams(data);
    const url = `/visaPartner?${queryParams.toString()}`;
    Http.get(url).then(res=>{
      setDataList(res.data.visaPatner.data);
      setPagination({
        total: res.data.visaPatner.total,
        show: res.data.visaPatner.to,
        links: res.data.visaPatner.links,
      });
      console.log(res);
      // setLoaderShow(false)
    })
  }
  const paginationFun = (url) => {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res);
        console.log("pass");
        setPagination({
          total: res.data.visaPatner.total,
          show: res.data.visaPatner.to,
          links: res.data.visaPatner.links,
        });
        setDataList(res.data.visaPatner.data);
        // setLoaderShow(false);
      });
  };
  const inputControl = (e) => {
    e.persist();
    setSearchData({ ...searcData, [e.target.name]: e.target.value });
    console.log(searcData);
    
  };
  const { state } = useLocation();
  const [DataList, setDataList] = useState([]);
  useEffect(()=>{
    fetchData();
    if(state?.mgs==1){
      toast.success("Data Add Successfully");
    }
    if(state?.mgs==2){
      toast.success("Data Update Successfully");
    }
  },[])

  const fetchData =()=>{
    Http.get('/visaPartner').then(res=>{
      console.log(res);
      setPagination({
        total: res.data.visaPatner.total,
        show: res.data.visaPatner.to,
        links: res.data.visaPatner.links,
      });
      setDataList(res.data.visaPatner.data);
    })
  }
  
  const deleteFun=(id)=>{
    console.log(id);
    Http.delete(`/visaPartner/${id}`).then(res=>{
      fetchData();
      toast.success("Data Delete Successfully");
    }).catch((error) => {
      return error;
   });
  }

    return (
     <>
     <div class="content-wrapper pt-1">
<div className="card m-3">
          <ToastContainer/>
    <div className="card-header">
    <div className="card-title">
    <form onSubmit={submitSearch}>
                <div className="row">
                <div className="col-md-5 m-0">
                  <input onChange={inputControl}
                            type="text"
                            className="form-control"
                            name="name"
                            id="productNameLabel"
                            placeholder="Enter Agency"
                          />
                          
                </div>
                <div className="col-md-5">
                  <select   name="status"  onChange={inputControl}
                            className="form-control"
                            aria-label="Default select example">
                            <option value=" ">Select </option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>       
                </div>
                <div className="col-md-2">
                <button type="submit" class="btn btn-primary">
                          Search
                        </button>       
                </div>
                </div>
             </form>
    </div>

    <div className="card-tools">
    <Link className="btn btn-primary" to={'/admin/addVisaPatner'}>
        Add
      </Link>
    </div>
  </div>

  {/* /.card-header */}
  <div className="card-body table-responsive p-0">
    <table className="table table-head-fixed text-nowrap">
    <thead className="thead-light">
          <tr role="row">
            <th scope="col" >No</th>
            <th scope="col" >Agency</th>
            <th scope="col" >Person one First Name</th>
            <th scope="col" >Person two First Name</th>
            <th scope="col" >Status</th>
            <th scope="col" >Action</th>
            </tr>
        </thead>
        <tbody>
        {DataList && DataList.map((item,index)=>(
                     <tr key={index}>
                      
                     <td>{++index}</td>
                     <td>{item.agency_name}</td>
                     <td>{item.person_one_first_name}</td>
                     <td>{item.person_two_first_name}</td>
                     <td>{
                      item.status ==1? "Active" : "Inactive"
                     }</td>
                     <td>
                     <div className="btn-group" role="group">
                     <Link to={`/admin/editVisaPatner/${item.id}/edit`}  className="btn btn-white btn-sm"> <i className="bi-pencil-fill me-1" /> </Link>
                     <Link to={`/admin/ShowVizaPatner/${item.id}/show`}  className="btn btn-white btn-sm"> <i class="bi-eye"></i> </Link>
                     <button className="btn btn-white btn-sm" onClick={()=>{deleteFun(item.id)}} > <i className="bi-trash dropdown-item-icon" /></button>
                     </div>
                     </td>
                   </tr>
                ))}
          </tbody>
    </table>
    <div className="card-footer">
              <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                <div className="col-sm mb-2 mb-sm-0">
                  <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                    <span className="me-2">Showing:</span>
                    {/* Select */}
                    <div className="tom-select-custom p-1">
                      {pagination.show}
                    </div>
                    {/* End Select */}
                    <span className="text-secondary me-2">of</span>
                    {/* Pagination Quantity */}
                    <span id="datatableWithPaginationInfoTotalQty">
                      {pagination.total}
                    </span>
                  </div>
                </div>
                {/* End Col */}
                <div className="col-sm-auto">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    {/* Pagination */}
                    <nav
                      id="datatablePagination"
                      aria-label="Activity pagination"
                    >
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="datatable_paginate"
                      >
                        <ul
                          id="datatable_pagination"
                          className="pagination datatable-custom-pagination"
                        >
                          {pagination.links &&
                            pagination.links.map((item, index) => (
                              <li
                                key={index}
                                className={
                                  "paginate_item page-item " +
                                  (item.active ? "active" : "")
                                }
                              >
                                {item.label.match(/^\d+$/) && (
                                  <div
                                    onClick={() => paginationFun(item.url)}
                                    className="paginate_button previous page-link"
                                  >
                                    <span aria-hidden="true">{item.label}</span>
                                  </div>
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
  </div>
  {/* /.card-body */}
          </div>





</div>
     </>
    );
  }
  
export default VisaPatnerList;
  