import React from "react";
import axios from "axios";
import Http from "../../BaseUrl/Http";
import { useEffect,useState } from "react";
import { Link,useLocation} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Component/Loader";

function VisaApplicatioList() {
  
  const { state } = useLocation();
  const [LoaderShow, setLoaderShow] = useState(true);
  const [statusChange, setStatusChange] = useState([]);
  const [searcData, setSearchData] = useState({
    name: "",
    status: "",
  });
  const [pagination, setPagination] = useState({
    total: "",
    show: "",
    links: "",
  });
  function submitSearch(e){
    const data = {
      name: searcData.name,
      status: searcData.status,
      id: localStorage.getItem("visaPatnerId"),
    };
    e.preventDefault();
    const queryParams = new URLSearchParams(data);
    const url = `/visaPatnerList?${queryParams.toString()}`;
    Http.get(url).then(res=>{
      setDataList(res.data.application.data);
      console.log(res);
      setPagination({
        total: res.data.application.total,
        show: res.data.application.to,
        links: res.data.application.links,
      });
      // setLoaderShow(false)
    })
  }
  const inputControl = (e) => {
    e.persist();
    setSearchData({ ...searcData, [e.target.name]: e.target.value });
    console.log(searcData);
    
  };
  const [DataList, setDataList] = useState([]);
  useEffect(()=>{
    fetchData();
    setLoaderShow(false);
    if(state?.mgs==1){
      toast.success("Data Add Successfully");
    }
    if(state?.mgs==2){
      toast.success("Data Update Successfully");
    }
  },[])
  const fetchData =()=>{
    const data = {
      id: localStorage.getItem("visaPatnerId"),
    };
    const queryParams = new URLSearchParams(data);
    const url = `/visaPatnerList?${queryParams.toString()}`;
    Http.get(url).then(res=>{
      console.log(res);
      setStatusChange(res.data.status);
      setPagination({
        total: res.data.application.total,
        show: res.data.application.to,
        links: res.data.application.links,
      });
      setDataList(res.data.application.data);
    })
  }

  const paginationFun = (url) => {
    console.log('fddf'+url);
    
    const data = {
      id: localStorage.getItem("visaPatnerId"),
    };
    const queryParams = new URLSearchParams(data);
    axios
      .get(url+'&'+queryParams.toString(), {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res);
        console.log("pass");
        setPagination({
          total: res.data.application.total,
          show: res.data.application.to,
          links: res.data.application.links,
        });
        setDataList(res.data.application.data);
        setLoaderShow(false);
      });
  };
  const changeFunStatus=(event)=>{
    
    const formData=new FormData();
    formData.append('id',event.target.id);
    formData.append('statusId', event.target.value);

    Http.post(`/changeStatus`,formData).then((res) => {

      toast.success("Change Status Successfully");
      
    }).catch((error) => {
      if(error.response.status==400){
       console.log(error.response);
      //  setErrorMessage(error.response.data.message);
      }
      return error;
   })
    
  }
    return (
     <>
 {
      LoaderShow ?(
        <Loading/>
      ):(
        <div class="content-wrapper pt-1">

<div className="card m-3">
          <ToastContainer/>
    <div className="card-header">
    <div className="card-title">
    <form onSubmit={submitSearch}>
                <div className="row">
                <div className="col-md-5 m-0">
                  <input onChange={inputControl}
                            type="text"
                            className="form-control"
                            name="name"
                            id="productNameLabel"
                            placeholder="Enter First Name"
                          />
                          
                </div>
                <div className="col-md-5">
                <select  onChange={inputControl}    name="status" 
                            class="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {statusChange && statusChange.map((itemData,index2)=>(
                              <option value={itemData.id} id={index2}>{itemData.name}</option>
                              ))}
                          </select>      
                </div>
                <div className="col-md-2">
                <button type="submit" class="btn btn-primary">
                          Search
                        </button>       
                </div>
                </div>
             </form>
    </div>
  </div>

  {/* /.card-header */}
  <div className="card-body table-responsive p-0">
    <table className="table table-head-fixed text-nowrap">
    <thead className="thead-light">
          <tr role="row">
          <th scope="col"  >No</th>
            <th scope="col" >First Name</th>
            <th scope="col" >Surname</th>
            <th scope="col" >Phone</th>
            <th scope="col" >Status</th>
            <th scope="col" >Action</th>
            </tr>
        </thead>
        <tbody>
         
        {DataList && DataList.map((item,index)=>(
                     <tr key={index}>
                      
                     <td>{++index}</td>
                     <td>{item.first_name}</td>
                     <td>{item.surname}</td>
                     <td>{item.phone}</td>
                     <td>     <select  onChange={changeFunStatus}    name="status" required id={item.id} 
                            class="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {statusChange && statusChange.map((itemData,index2)=>(
                              <option value={itemData.id} id={index2} selected={itemData.id == item.status ? "selected" : ""}>{itemData.name}</option>
                              ))}
                          </select></td>
                     <td>
                     <div className="btn-group" role="group">
                     {/* /visaPatner/home/:id/fileUpload */}
                     {/* <Link to={`/visaPatner/home/${item.id}/fileUpload`}  className="btn btn-white btn-sm"> <i className="bi-pencil-fill me-1" /> </Link> */}
                     <Link to={`/visaPatner/home/list/${item.id}/show`}  className="btn btn-white btn-sm"> <i class="bi-eye"></i> </Link>
                     </div>
                     </td>
                   </tr>
                ))}
          </tbody>
    </table>
    <div className="card-footer">
              <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                <div className="col-sm mb-2 mb-sm-0">
                  <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                    <span className="me-2">Showing:</span>
                    {/* Select */}
                    <div className="tom-select-custom p-1">
                      {pagination.show}
                    </div>
                    {/* End Select */}
                    <span className="text-secondary me-2">of</span>
                    {/* Pagination Quantity */}
                    <span id="datatableWithPaginationInfoTotalQty">
                      {pagination.total}
                    </span>
                  </div>
                </div>
                {/* End Col */}
                <div className="col-sm-auto">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    {/* Pagination */}
                    <nav
                      id="datatablePagination"
                      aria-label="Activity pagination"
                    >
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="datatable_paginate"
                      >
                        <ul
                          id="datatable_pagination"
                          className="pagination datatable-custom-pagination"
                        >
                          {pagination.links &&
                            pagination.links.map((item, index) => (
                              <li
                                key={index}
                                className={
                                  "paginate_item page-item " +
                                  (item.active ? "active" : "")
                                }
                              >
                                {item.label.match(/^\d+$/) && (
                                  <div
                                    onClick={() => paginationFun(item.url)}
                                    className="paginate_button previous page-link"
                                  >
                                    <span aria-hidden="true">{item.label}</span>
                                  </div>
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
  </div>
  {/* /.card-body */}
          </div>

</div>
      )
     }
     </>
    );
  }
  
export default VisaApplicatioList;
  