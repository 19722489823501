// import axios from "axios";

// const token = localStorage.getItem("token");

// export default axios.create({
//   baseURL: "http://127.0.0.1:8000/api/",
//   // baseURL: "http://itloader.tech/visa/api/",
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`, // add token to Authorization header
//   },
// });

import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  //baseURL: "http://127.0.0.1:8000/api/",
   baseURL: "https://api.vizasure.com/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the token in the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Refresh the token here
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await axios.post("https://api.vizasure.com/api/refresh-token", { token: refreshToken });

        const newToken = response.data.token;
        localStorage.setItem("token", newToken);

        // Update the Authorization header with the new token
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

        return axiosInstance(originalRequest);
      } catch (err) {
        // Handle token refresh failure (e.g., redirect to login)
        console.error("Token refresh failed:", err);
        // Optionally clear tokens and redirect to login
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

