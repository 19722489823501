import React from "react";
import { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function EditStaff() {

    let {id}=useParams();

    useEffect(()=>{
      fetchData();
    },[])

    const [staff,setStaff]=useState({
        email:'',
        first_name:'',
        surname:'',
        phone:'',
        // national_id_attachment:'',
        address:'',
        status:'',
    
      })

      // const [file, setFile] = useState()

      // function handleChange(event) {
      //   setFile(event.target.files[0])
      // }
  
  
    const fetchData =()=>{
      
      Http.get(`/staff/${id}/edit`).then(res=>{
        console.log(res);
        setStaff({
            email:res.data.staff.email,
            first_name:res.data.staff.first_name,
            surname:res.data.staff.email,
            phone:res.data.staff.phone,
            national_id_attachment:res.data.staff.national_id_attachment,
            address:res.data.staff.address,
            status:res.data.staff.status,
        })
      })
    }
  
    const nav=useNavigate();
    const [errorMessage,setErrorMessage]=useState([]);
  
    const inputControl=(e)=>{
      e.persist();
      setStaff({...staff,[e.target.name]:e.target.value});
    }
  
    const updateData=(e)=>{
      e.preventDefault();
      // const data={
      //   email:staff.email,
      //   first_name:staff.first_name,
      //   surname:staff.email,
      //   phone:staff.phone,
      //   national_id_attachment:staff.national_id_attachment,
      //   address:staff.address,
      //   status:staff.status,
      // }

      const formData=new FormData();
      formData.append("_method", "PUT");
      // formData.append('national_id_attachment', file);
      formData.append('first_name', staff.first_name);
      formData.append('email', staff.email);
      formData.append('phone', staff.phone);
      formData.append('surname', staff.surname);
      formData.append('address', staff.address);
      formData.append('status', staff.status);
      
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
  
  
      Http.post(`/staff/${id}`,formData,config).then(res=>{

        console.log(res);
        nav('/admin/staffList',{
          state: {
            mgs: 2,
          },
        });
  
      }).catch((error) => {
       if(error.response.status==400){
        console.log(error.response);
        setErrorMessage(error.response.data.message);
       }
       return error;
    })
    }
 
    return (
     <>
    <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">Update Staff</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Staff Information</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <form onSubmit={updateData}>
            <div className="row">
            <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">First Name </label>
              <input type="text" className="form-control" onChange={inputControl} value={staff.first_name}  name="first_name" id="productNameLabel" placeholder="Enter first name" required />
              <span className="text-danger">{errorMessage.first_name}</span>
              <span className="text-danger"></span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Surname </label>
              <input type="text" className="form-control" onChange={inputControl} value={staff.surname}   name="surname" id="productNameLabel" placeholder="Enter Surname" required/>
              <span className="text-danger">{errorMessage.surname}</span>
              <span className="text-danger"></span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Email </label>
              <input type="email" className="form-control" onChange={inputControl} value={staff.email}  name="email" id="productNameLabel" placeholder="Enter Email" required/>
              <span className="text-danger">{errorMessage.email}</span>
              <span className="text-danger"></span>
                </div>
                {/* End Form */}
              </div>
          
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Phone </label>
              <input type="number" className="form-control" onChange={inputControl} value={staff.phone}   name="phone" id="productNameLabel" placeholder="Enter phone" required/>
              <span className="text-danger">{errorMessage.phone}</span>
              <span className="text-danger"></span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Address </label>
              <input type="text" className="form-control" onChange={inputControl} value={staff.address}  name="address" id="productNameLabel" placeholder="Enter address" required/>
              <span className="text-danger">{errorMessage.address}</span>
              <span className="text-danger"></span>
                </div>
                {/* End Form */}
              </div>
              {/* <div className="col-sm-6">

                <div className="mb-4">
                  <label htmlFor="SKULabel" className="form-label">File</label>
                  <input type="file" className="form-control" onChange={handleChange}   name="national_id_attachment" id="SKULabel" placeholder=""  required/>
                
                  <span className="text-danger">{errorMessage.national_id_attachment}</span>
                </div>
              </div> */}
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            Status
                          </label>
                          <select required
                            onChange={inputControl}
                            name="status"
                            className="form-control"
                            aria-label="Default select example">
                            <option value=" ">
                              Select 
                            </option>
                            <option value="1"  selected={1 == staff.status ? "selected" : ""}>Active</option>
                            <option value="0"  selected={0 == staff.status ? "selected" : ""}>Inactive</option>
                          </select>
                          {/* <input type="text" className="form-control" onChange={inputControl} value={country.status} name="status" id="SKULabel" placeholder=""  /> */}
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-12">
                {/* Form */}
                <button type="submit" class="btn btn-primary">Save</button>
                {/* End Form */}
              </div>
            </div>
            </form>
            {/* End Quill */}
          </div>
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>
    </div>
    {/* End Row */}
 
  </div>
</div>
     </>
    );
  }
  
export default EditStaff;
  