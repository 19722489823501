import React from "react";
import { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function ShowClient() {

    let {id}=useParams();

    useEffect(()=>{
      fetchData();
    },[])

    const [client,setClient]=useState({
        agency_name:'',
        person_one_first_name:'',
        person_two_first_name:'',
        person_one_surname:'',
        person_two_surname:'',
        person_one_email:'',
        person_two_email:'',
        person_one_phone:'',
        person_two_phone:'',
        address:'',
        status:'',
      })
  
    const fetchData =()=>{
      
      Http.get(`/client/${id}`).then(res=>{
        console.log(res);
        setClient({
            agency_name:res.data.client.agency_name,
            person_one_first_name:res.data.client.person_one_first_name,
            person_two_first_name:res.data.client.person_two_first_name,
            person_one_surname:res.data.client.person_one_surname,
            person_two_surname:res.data.client.person_two_surname,
            person_one_email:res.data.client.person_one_email,
            person_two_email:res.data.client.person_two_email,
            person_one_phone:res.data.client.person_one_phone,
            person_two_phone:res.data.client.person_two_phone,
            address:res.data.client.address,
            status:res.data.client.status,
          });
      })
    }
 
    return (
     <>
   <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">Show Client</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Client Detail</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <div className="row">
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                    <b>Agency : </b> {client.agency_name}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person One First Name : </b> {client.person_one_first_name}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person One Surname : </b> {client.person_one_surname}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person One Email : </b> {client.person_one_email}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person One Phone : </b> { client.person_one_phone}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person Two First Name : </b> {client.person_two_first_name}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person Two Surname : </b> {client.person_two_surname}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person Two Email : </b> {client.person_two_email}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Person Two Phone : </b> {client.person_two_phone}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Address : </b> {client.address}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Status : </b> {client.status ==1? "Active" : "Inactive"}
                </div>
                  {/* End Form */}
            </div>
            </div>
            {/* End Quill */}
          </div>
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>
    </div>
    {/* End Row */}
 
  </div>
</div>
     </>
    );
  }
  
export default ShowClient;
  