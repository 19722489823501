import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function EditStatus() {
  let { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const [status, setStatus] = useState({
    name: "",
  });

  const fetchData = () => {
    Http.get(`/status/${id}/edit`).then((res) => {
        console.log(res);
        
        setStatus({
        name: res.data.status.name,
      });
    });
  };

  const nav = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);

  const inputControl = (e) => {
    e.persist();
    setStatus({ ...status, [e.target.name]: e.target.value });
  };

  const updateData = (e) => {
    e.preventDefault();
    const data = {
      name: status.name,
    };

    Http.put(`/status/${id}`, data)
      .then((res) => {
        nav("/admin/StatusList", {
          state: {
            mgs: 2,
          },
        });
      })
      .catch((error) => {
        if (error.response.status == 400) {
          console.log(error.response);
          setErrorMessage(error.response.data.message);
        }
        return error;
      });
  };
  return (
    <>
          <div class="content-wrapper pt-1">
        {/* Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="p-3">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h4 className="page-header-title">Update Status</h4>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}
          <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-0">
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                {/* Header */}
                <div className="card-header">
                  <h5 className="card-header-title">Status Information</h5>
                </div>
                <div className="card-body">
                  {/* End Form */}
                  <form onSubmit={updateData}>
                    <div className="row">
                      <div className="col-sm-6">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            Name{" "}
                          </label>
                          <input
                            type="text" required
                            className="form-control"
                            onChange={inputControl}
                            value={status.name}
                            name="name"
                            id="productNameLabel"
                            placeholder="Enter Status"
                          />
                          <span className="text-danger">
                            {errorMessage.name}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      {/* <div className="col-sm-6">
                        <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            status
                          </label>
                          <select
                            onChange={inputControl}
                            name="status"
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option
                              value=" "
                            >
                              Select Country
                            </option>
                            <option value="1"  selected={1 == country.status ? "selected" : ""}>Active</option>
                            <option value="0"  selected={0 == country.status ? "selected" : ""}>Inactive</option>
                          </select>
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                      </div> */}
                      {/* <input type="hidden" name="status" value= /> */}
                      <div className="col-sm-12">
                        {/* Form */}
                        <button type="submit" class="btn btn-primary">
                          Save
                        </button>
                        {/* End Form */}
                      </div>
                    </div>
                  </form>
                  {/* End Quill */}
                </div>
                {/* Body */}
              </div>

              {/* End Card */}
            </div>
          </div>
          {/* End Row */}
        </div>
        {/* End Footer */}
      </div>
    </>
  );
}

export default EditStatus;
