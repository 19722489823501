import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function AddCountry() {
  const nav = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);
  const [country, setCountry] = useState({
    name: "",
    status: "1",
  });

  const inputControl = (e) => {
    e.persist();
    setCountry({ ...country, [e.target.name]: e.target.value });
  };

  const saveData = (e) => {
    e.preventDefault();
    const data = {
      name: country.name,
      status: country.status,
    };
    console.log(data);

    Http.post("/country", data)
      .then((res) => {
        nav("/admin/CountryList", {
          state: {
            mgs: 1,
          },
        });
      })
      .catch((error) => {
        if (error.response.status == 400) {
          console.log(error.response);
          setErrorMessage(error.response.data.message);
        }
        return error;
      });
  };

  return (
    <>
          <div class="content-wrapper pt-1">
        {/* Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="p-3">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h4 className="page-header-title">Add Country</h4>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}
          <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-0">
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                {/* Header */}
                <div className="card-header">
                  <h6 className="card-header-title">Country information</h6>
                </div>
                {/* End Header */}
                {/* Body */}
                <div className="card-body">
                  {/* End Form */}
                  <form onSubmit={saveData}>
                    <div className="row">
                      <div className="col-sm-6">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={inputControl}
                            name="name"
                            required
                            id="productNameLabel"
                            placeholder="Enter Country"
                          />
                          <span className="text-danger">
                            {errorMessage.name}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      <input type="hidden" name="status" value='1' />
                      {/* <div className="col-sm-6">
                 
                        <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            status
                          </label>
                          <select  onChange={inputControl}  name="status"
                            class="form-select"
                            aria-label="Default select example">
                               <option value="1">Select </option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                      </div> */}
                      <div className="col-sm-12">
                        {/* Form */}
                        <button type="submit" class="btn btn-primary">
                          Save
                        </button>
                        {/* End Form */}
                      </div>
                    </div>
                  </form>
                  {/* End Quill */}
                </div>
                {/* Body */}
              </div>

              {/* End Card */}
            </div>
          </div>
          {/* End Row */}
        </div>

        {/* End Footer */}
      </div>
    </>
  );
}

export default AddCountry;
