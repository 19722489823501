import React from "react";

function Footer() {
  
    return (
     <>
{/* /.control-sidebar */}
{/* Main Footer */}
<footer className="main-footer">
  {/* To the right */}
  <div className="float-right d-none d-sm-inline">
   
  </div>
  {/* Default to the left */}
  <strong>Copyright © 2024 VIZASURE </strong> 
</footer>



     </>
    );
  }
  
  
export default Footer;
  