import React from "react";
import { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function ShowStaff() {

    let {id}=useParams();

    useEffect(()=>{
      fetchData();
    },[])

    const [staff,setStaff]=useState({
        email:'',
        first_name:'',
        surname:'',
        phone:'',
        national_id_attachment:'',
        address:'',
        status:'',
    
      })
  
    const fetchData =()=>{
      
      Http.get(`/staff/${id}`).then(res=>{
        console.log(res);
        setStaff({
            email:res.data.staff.email,
            first_name:res.data.staff.first_name,
            surname:res.data.staff.email,
            phone:res.data.staff.phone,
            national_id_attachment:res.data.staff.national_id_attachment,
            address:res.data.staff.address,
            status:res.data.staff.status,
        })
      })
    }
 
    return (
     <>
     <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">Show Staff</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Staff Detail</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <div className="row">
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                    <b>First Name : </b> {staff.first_name}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Surname : </b> {staff.surname}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Email : </b> {staff.email}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Phone : </b> {staff.phone}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Address : </b> {staff.address}
                </div>
                  {/* End Form */}
            </div>
            <div className="col-sm-4">
                {/* Form */}
                <div className="mb-4">
                <b>Status : </b> { staff.status ==1? "Active" : "Inactive"}
                </div>
                  {/* End Form */}
            </div>
            </div>
            {/* End Quill */}
          </div>
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>
    </div>
    {/* End Row */}
 
  </div>
</div>
     </>
    );
  }
  
export default ShowStaff;
  