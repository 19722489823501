import React from "react";
import logoo from "../../Image/VisaSure_Logo_rect.png"
import Http from "../../BaseUrl/Http";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Login() {
    const nav=useNavigate();
    const [login,setLogin]=useState({
        email:'',
        password:''
      })

    const[errorMgs,setErrorMgs]=useState([])
    const inputControl=(e)=>{
        e.persist();
        setLogin({...login,[e.target.name]:e.target.value});
        console.log(login);
      }
      const loginFun=(e)=>{
        e.preventDefault();
        const data={
          email:login.email,
          password:login.password
        }
    
        Http.post('/login',data).then(res=>{

          console.log(res);
            if(res.request.status==200){
             if(res.data.user.type==0){
              nav('/admin');
              // sessionStorage.setItem('hasReloaded', false);
              localStorage.setItem("type", "0");
              localStorage.setItem("token",res.data.user.token );
             }
             if(res.data.user.type==2){
              localStorage.setItem("token",res.data.user.token );
              localStorage.setItem("type", "2");
              localStorage.setItem("name",res.data.user.name );
              nav('/staff/home');
              
             }
             if(res.data.user.type==3){
              localStorage.setItem("token",res.data.user.token );
              localStorage.setItem("type", "3");
              localStorage.setItem("name",res.data.user.name );
              localStorage.setItem("clientId",res.data.user.clientId );
              nav('/client/home');
             }
             if(res.data.user.type==4){
              nav('/visaPatner/home');
              localStorage.setItem("token",res.data.user.token );
              localStorage.setItem("name",res.data.user.name );
              localStorage.setItem("type", "4");
              localStorage.setItem("visaPatnerId",res.data.user.visaPatnerId);
             }
            }
    
        }).catch((error) => {
         if(error.response.status==400){
          console.log(error.response);
          setErrorMgs(error.response.data.message);
         }
         if(error.response.status==404){
            toast.error("Invalid credentials");
         }
         return error;
      })
      }
    return (
     <>
  <div class="content-wrapper pt-1">
  <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{height: '32rem', backgroundImage: 'url(assets/svg/components/card-6.svg)'}}>
    {/* Shape */}
    <div className="shape shape-bottom zi-1">
      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
        <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
      </svg>
    </div>
    {/* End Shape */}
  </div>
  {/* Content */}
  <div className="container py-5 py-sm-7"  style={{marginLeft: '-100px',marginTop:'25px'}}>
    {/* <a className="d-flex justify-content-center mb-5" href="index.html">
      <img className="zi-2" src="assets/svg/logos/logo.svg" alt="Image Description" style={{width: '8rem'}} />
    </a> */}
    <ToastContainer/>
    <div className="mx-auto" style={{maxWidth: '30rem'}}>
      {/* Card */}
      <div className="card card-lg mb-5">
        <div className="card-body">
          {/* Form */}
          <form onSubmit={loginFun} className="js-validate needs-validation" noValidate>
            <div className="text-center">
              <div className="mb-5">
              <img src={logoo} alt="sdfdfsfds" style={{ width: '28%' }}/>
                {/* <h1 className="display-5">Log in</h1> */}
              </div>
            
            </div>
            {/* Form */}
            <div className="mb-4">
              <label className="form-label" htmlFor="signinSrEmail">Email</label>
              <input type="email" className="form-control form-control-lg" name="email"  onChange={inputControl} required />
              <span className="invalid-feedback">Please enter a valid email address.</span>
              <span className="text-danger">{errorMgs.email}</span>
            </div>
            {/* End Form */}
            {/* Form */}
            <div className="mb-4">
              <label className="form-label w-100" htmlFor="signupSrPassword" tabIndex={0}>
                <span className="d-flex justify-content-between align-items-center">
                  <span>Password</span>
                  {/* <a className="form-label-link mb-0" href="authentication-reset-password-basic.html">Forgot Password?</a> */}
                </span>
              </label>
              <div className="input-group input-group-merge" data-hs-validation-validate-class>
                <input type="password" className="js-toggle-password form-control form-control-lg"  onChange={inputControl} name="password" />
              </div>
              {/* <span className="invalid-feedback">Please enter a valid password.</span> */}
              <span className="text-danger">{errorMgs.password}</span>
            </div>
            {/* End Form */}
            {/* Form Check */}
            {/* End Form Check */}
            <div className="d-grid">
              <button type="submit" className="btn btn-primary btn-lg">Log in</button>
            </div>
          </form>
          {/* End Form */}
        </div>
      </div>
    </div>
  </div>
  {/* End Content */}
</div>
     </>
    );
  }
  
export default Login;
  