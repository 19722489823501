import './App.css';
import Header from './Layout/Header';
import SideBar from './Layout/SideBar';
import Footer from './Layout/Footer';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <>
    <div className="wrapper">
    <Header/>
    <SideBar/>
    <Outlet/>
    <Footer/>
    </div>
    </>
  );
}

export default App;
