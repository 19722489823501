import React from "react";
import { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function EditVisaPatner() {
  const [country, setCountry] = useState([]);

    let {id}=useParams();

    useEffect(()=>{
      fetchData();
      fetchData2();
    },[])

    const fetchData2 =()=>{
      Http.get('/Application/create').then(res=>{
        console.log(res);
        setCountry(res.data.country);
      })
    }
    const [visaPatner,setVisaPatner]=useState({
        agency_name:'',
        person_one_first_name:'',
        person_two_first_name:'',
        person_one_surname:'',
        person_two_surname:'',
        person_one_email:'',
        person_two_email:'',
        person_one_phone:'',
        person_two_phone:'',
        address:'',
        status:'',
        password:'',
        target_eu_countries:''
      })
  
  
    const fetchData =()=>{
      Http.get(`/visaPartner/${id}/edit`).then(res=>{
        console.log(res);
        
        setVisaPatner({
            agency_name:res.data.vizaPatner.agency_name,
            person_one_first_name:res.data.vizaPatner.person_one_first_name,
            person_two_first_name:res.data.vizaPatner.person_two_first_name,
            person_one_surname:res.data.vizaPatner.person_one_surname,
            person_two_surname:res.data.vizaPatner.person_two_surname,
            person_one_email:res.data.vizaPatner.person_one_email,
            person_two_email:res.data.vizaPatner.person_two_email,
            person_one_phone:res.data.vizaPatner.person_one_phone,
            person_two_phone:res.data.vizaPatner.person_two_phone,
            address:res.data.vizaPatner.address,
            status:res.data.vizaPatner.status,
            target_eu_countries:res.data.vizaPatner.target_eu_countries
          })
          console.log(visaPatner);
          
      })
    }
  
    const nav=useNavigate();
    const [errorMessage,setErrorMessage]=useState([]);
  
    const inputControl=(e)=>{
      e.persist();
      setVisaPatner({...visaPatner,[e.target.name]:e.target.value});
      console.log(visaPatner);
    }
  
    const updateData=(e)=>{
      e.preventDefault();
      const data={
        agency_name:visaPatner.agency_name,
        person_one_first_name:visaPatner.person_one_first_name,
        person_two_first_name:visaPatner.person_two_first_name,
        person_one_surname:visaPatner.person_one_surname,
        person_two_surname:visaPatner.person_two_surname,
        person_one_email:visaPatner.person_one_email,
        person_two_email:visaPatner.person_two_email,
        person_one_phone:visaPatner.person_one_phone,
        person_two_phone:visaPatner.person_two_phone,
        address:visaPatner.address,
        status:visaPatner.status,
        target_eu_countries:visaPatner.target_eu_countries
      }
      console.log(data);
      
  
      Http.put(`/visaPartner/${id}`,data).then(res=>{
        
        nav('/admin/visaPatnerList',{
          state: {
            mgs: 2,
          },
        });
  
      }).catch((error) => {
       if(error.response.status==400){
        console.log(error.response);
        setErrorMessage(error.response.data.message);
       }
       return error;
    })
    }

 
    return (
     <>
   <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">Update Visa Patner</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Visa Patner information</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <form onSubmit={updateData}>
            <div className="row">
            <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Agency Name </label>
              <input type="text" className="form-control"  onChange={inputControl} value={visaPatner.agency_name}    name="agency_name" id="productNameLabel" placeholder="Enter agency name" required />
              <span className="text-danger">{errorMessage.agency_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One First Name </label>
              <input type="text" className="form-control" onChange={inputControl} value={visaPatner.person_one_first_name}    name="person_one_first_name" id="productNameLabel" placeholder="Enter first name" required />
              <span className="text-danger">{errorMessage.person_one_first_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One Surname </label>
              <input type="text" className="form-control" onChange={inputControl} value={visaPatner.person_one_surname}   name="person_one_surname" id="productNameLabel" placeholder="Enter Surname" required />
              <span className="text-danger">{errorMessage.person_one_surname}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One Email </label>
              <input type="email" className="form-control" onChange={inputControl} value={visaPatner.person_one_email}    name="person_one_email" id="productNameLabel" placeholder="Enter Email" required />
              <span className="text-danger">{errorMessage.person_one_email}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One Phone </label>
              <input type="number" className="form-control" onChange={inputControl} value={visaPatner.person_one_phone}   name="person_one_phone" id="productNameLabel" placeholder="Enter Phone" required />
              <span className="text-danger">{errorMessage.person_one_phone}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two First Name </label>
              <input type="text" className="form-control" onChange={inputControl} value={visaPatner.person_two_first_name}   name="person_two_first_name" id="productNameLabel" placeholder="Enter first name" required />
              <span className="text-danger">{errorMessage.person_two_first_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two Surname </label>
              <input type="text" className="form-control" onChange={inputControl} value={visaPatner.person_two_surname}   name="person_two_surname" id="productNameLabel" placeholder="Enter Surname" required />
              <span className="text-danger">{errorMessage.person_two_surname}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two Email </label>
              <input type="email" className="form-control" onChange={inputControl} value={visaPatner.person_two_email}   name="person_two_email" id="productNameLabel" placeholder="Enter Email" required />
              <span className="text-danger">{errorMessage.person_two_email}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two Phone </label>
              <input type="number" className="form-control" onChange={inputControl} value={visaPatner.person_two_phone}   name="person_two_phone" id="productNameLabel" placeholder="Enter Email" required />
              <span className="text-danger">{errorMessage.person_two_phone}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Address </label>
              <input type="text" className="form-control" onChange={inputControl} value={visaPatner.address}   name="address" id="productNameLabel" placeholder="Enter address" required/>
              <span className="text-danger">{errorMessage.address}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
              <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                           Country
                          </label>
                          <select  onChange={inputControl} required
                           name="target_eu_countries"
                               className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {country && country.map((item,index)=>(
                              <option value={item.id} selected={item.id == visaPatner.target_eu_countries ? "selected" : ""}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.residing_country}
                          </span>
                        </div>
                </div>

              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            Status
                          </label>
                          <select required
                            onChange={inputControl}
                            name="status"
                              className="form-control"
                            aria-label="Default select example"
                          >
                            <option
                              value=" "
                            >
                              Select 
                            </option>
                            <option value="1"  selected={1 == visaPatner.status ? "selected" : ""}>Active</option>
                            <option value="0"  selected={0 == visaPatner.status ? "selected" : ""}>Inactive</option>
                          </select>
                          {/* <input type="text" className="form-control" onChange={inputControl} value={country.status} name="status" id="SKULabel" placeholder=""  /> */}
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-12">
                {/* Form */}
                <button type="submit" class="btn btn-primary">Save</button>
                {/* End Form */}
              </div>
            </div>
            </form>
            {/* End Quill */}
          </div>
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>
    </div>
    {/* End Row */}
 
  </div>
  {/* End Content */}
</div>
     </>
    );
  }
  
export default EditVisaPatner;
  