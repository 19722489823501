import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function AddClient() {
 
  const nav=useNavigate();
  const [errorMessage,setErrorMessage]=useState([]);
  const [client,setClient]=useState({
    agency_name:'',
    person_one_first_name:'',
    person_two_first_name:'',
    person_one_surname:'',
    person_two_surname:'',
    person_one_email:'',
    person_two_email:'',
    person_one_phone:'',
    person_two_phone:'',
    address:'',
    status:'',
    password:''
  })

  const inputControl=(e)=>{
    e.persist();
    setClient({...client,[e.target.name]:e.target.value});
  }

  const saveData=(e)=>{
    e.preventDefault();
    const data={
      agency_name:client.agency_name,
      person_one_first_name:client.person_one_first_name,
      person_two_first_name:client.person_two_first_name,
      person_one_surname:client.person_one_surname,
      person_two_surname:client.person_two_surname,
      person_one_email:client.person_one_email,
      person_two_email:client.person_two_email,
      person_one_phone:client.person_one_phone,
      person_two_phone:client.person_two_phone,
      address:client.address,
      status:client.status,
      password:btoa(client.password),
    }
    console.log(data);

    Http.post('/client',data).then(res=>{
      console.log(res);
      nav('/admin/clientList',{
        state: {
          mgs: 1,
        },
      });

    }).catch((error) => {
     if(error.response.status==400){
      console.log(error.response);
      setErrorMessage(error.response.data.message);
     }
     return error;
  })
}
    return (
     <>
    <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">Add Client</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Client information</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <form  onSubmit={saveData}>
            <div className="row">
            <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Agency Name </label>
              <input type="text" className="form-control" onChange={inputControl}  name="agency_name" id="productNameLabel" placeholder="Enter agency name" required />
              <span className="text-danger">{errorMessage.agency_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One First Name </label>
              <input type="text" className="form-control" onChange={inputControl}  name="person_one_first_name" id="productNameLabel" placeholder="Enter first name" required/>
              <span className="text-danger">{errorMessage.person_one_first_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One Surname </label>
              <input type="text" className="form-control" onChange={inputControl}  name="person_one_surname" id="productNameLabel" placeholder="Enter Surname" required/>
              <span className="text-danger">{errorMessage.person_one_surname}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One Email </label>
              <input type="email" className="form-control" onChange={inputControl}  name="person_one_email" id="productNameLabel" placeholder="Enter Email" required/>
              <span className="text-danger">{errorMessage.person_one_email}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person One Phone </label>
              <input type="number" className="form-control" onChange={inputControl}  name="person_one_phone" id="productNameLabel" placeholder="Enter Phone" required/>
              <span className="text-danger">{errorMessage.person_one_phone}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two First Name </label>
              <input type="text" className="form-control" onChange={inputControl}  name="person_two_first_name" id="productNameLabel" placeholder="Enter first name" required/>
              <span className="text-danger">{errorMessage.person_two_first_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two Surname </label>
              <input type="text" className="form-control" onChange={inputControl}  name="person_two_surname" id="productNameLabel" placeholder="Enter Surname" required/>
              <span className="text-danger">{errorMessage.person_two_surname}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two Email </label>
              <input type="email" className="form-control" onChange={inputControl}  name="person_two_email" id="productNameLabel" placeholder="Enter Email" required/>
              <span className="text-danger">{errorMessage.person_two_email}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Person two Phone </label>
              <input type="number" className="form-control" onChange={inputControl}  name="person_two_phone" id="productNameLabel" placeholder="Enter Phone" required/>
              <span className="text-danger">{errorMessage.person_two_phone}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Address </label>
              <input type="text" className="form-control" onChange={inputControl} name="address" id="productNameLabel" placeholder="Enter address" required/>
              <span className="text-danger">{errorMessage.address}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Password </label>
              <input type="password" className="form-control" onChange={inputControl}  name="password" id="productNameLabel" placeholder="Enter password" required/>
              <span className="text-danger">{errorMessage.password}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            Status
                          </label>
                          <select  onChange={inputControl}  name="status" required
                            className="form-control"
                            aria-label="Default select example">
                               <option value="1">Select</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-12">
                {/* Form */}
                <button type="submit" class="btn btn-primary">Save</button>
                {/* End Form */}
              </div>
            </div>
            </form>
            {/* End Quill */}
          </div>
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>
    </div>
    {/* End Row */}
 
  </div>
</div>
     </>
    );
  }
  
export default AddClient;
  