import React from "react";
import { useNavigate } from "react-router-dom";
import headerImage from "../Image/shutdown.png";
import Http from "../BaseUrl/Http";
import logoo from "../Image/VisaSure_Logo_rect.png"
// /assets/img/160x160/img6.jpg

function Header() {
  const nav=useNavigate();
  const LogoOut=()=>{
    Http.post('/logout').then(res=>{
         localStorage.clear();
      nav('/');
    })
  }
    return (
     <>
     {/* <header id="header" className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white">
    <div className="navbar-nav-wrap">
    
      {console.log( localStorage.getItem("type"))
      }
      {    localStorage.getItem("type") =='0' &&
            <>
                  <b>Welcome to Admin</b>
              </>
            }
             {    localStorage.getItem("type") =='2' &&
            <>
                  <b>Welcome to Staff</b>
              </>
            }
              {    localStorage.getItem("type") =='3' &&
            <>
                  <b>Welcome to Client</b>
              </>
            }
                  {    localStorage.getItem("type") =='4' &&
            <>
                  <b>Welcome to Visa Partner</b>
              </>
            }

      <a className="navbar-brand" href="index.html" aria-label="Front">Welcome
        <img className="navbar-brand-logo" src={logoo} alt="Logo" data-hs-theme-appearance="default" />
        <img className="navbar-brand-logo-mini" src={logoo} alt="Logo" data-hs-theme-appearance="default" />
      </a>
   
      <div className="navbar-nav-wrap-content-start">

        <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">dfssdfdf
          <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template="<div class=&quot;tooltip d-none d-md-block&quot; role=&quot;tooltip&quot;><div class=&quot;arrow&quot;></div><div class=&quot;tooltip-inner&quot;></div></div>" data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse" />
          <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template="<div class=&quot;tooltip d-none d-md-block&quot; role=&quot;tooltip&quot;><div class=&quot;arrow&quot;></div><div class=&quot;tooltip-inner&quot;></div></div>" data-bs-toggle="tooltip" data-bs-placement="right" title="Expand" />
        </button>
    
    
        <div className="dropdown ms-2">

     
        </div>
      
      </div>
      <div className="navbar-nav-wrap-content-end">
    
        <ul className="navbar-nav">
          <li className="nav-item">
            <b>{localStorage.getItem("name")}</b>
          </li>
          <li className="nav-item">
        
            <div className="dropdown">
              <a className="navbar-dropdown-account-wrapper" href="#" id="accountNavbarDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation>
                <div className="avatar avatar-sm avatar-circle">
                  <img className="avatar-img"  src={headerImage} onClick={LogoOut} alt="Image Description" />
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown" style={{width: '16rem'}}>
                
             
                <button className="dropdown-item" onClick={LogoOut}>Sign out</button>
              </div>
            </div>
          
          </li>
        </ul>
    
      </div>
    </div>
  </header> */}

  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
    </li>
    <li className="nav-item mt-2">
    {    localStorage.getItem("type") =='0' &&
            <>
                  <b>Welcome to Admin</b>
              </>
            }
             {    localStorage.getItem("type") =='2' &&
            <>
                  <b>Welcome to Staff</b>
              </>
            }
              {    localStorage.getItem("type") =='3' &&
            <>
                  <b>Welcome to Client</b>
              </>
            }
                  {    localStorage.getItem("type") =='4' &&
            <>
                  <b>Welcome to Visa Partner</b>
              </>
            }
    </li>

  </ul>
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto">
  <li className="nav-item mt-2">
            <b>{localStorage.getItem("name")}</b>
          </li>
    <li className="nav-item dropdown">
      <a className="nav-link" href="#" onClick={LogoOut} >
      <img src={headerImage}  onClick={LogoOut} alt="" width={20} />
      </a>
    
    </li>
  </ul>
</nav>

     </>
    );
  }
  
export default Header;
  