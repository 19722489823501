import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Http from "../../BaseUrl/Http";
import fileImage from "../../Image/folder.png";

function ShowApplication() {
  let { id } = useParams();
  const nav = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);

  const [DataListClient, setDataListclient] = useState([]);
  const [DataListVisa, setDataListVisa] = useState([]);

  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);

  const fetchData2 = () => {
    const formData = new FormData();
    formData.append("id", id);
    Http.post("/getDocument", formData).then((res) => {
      setDataListclient(res.data.client);
      setDataListVisa(res.data.visaPartner);
    });
    
  };

  const hideChange=(idd)=>{
    const formData = new FormData();
    formData.append("idd", idd);
    Http.post("/getHideChange", formData).then((res) => {
      console.log(res);
      fetchData2();
      
    });
  }

  const [application, setApplication] = useState({
    id: "",
    first_name: "",
    surname: "",
    date: "",
    phone: "",
    email: "",
    nationality: "",
    residing_country: "",
    applying_country: "",
    client_agency: "",
    visa_partner_agency: "",
    status: "",
  });

  const fetchData = () => {
    Http.get(`/Application/${id}`).then((res) => {
      console.log(res);
      localStorage.setItem("clientIdd",res.data.application.client_agency);
      setApplication({
        first_name: res.data.application.first_name,
        surname: res.data.application.surname,
        date: res.data.application.date,
        phone: res.data.application.phone,
        email: res.data.application.email,
        nationality: res.data.nationality,
        residing_country: res.data.residing,
        applying_country: res.data.application.applying_country,
        client_agency: res.data.client,
        visa_partner_agency: res.data.visaPatner,
        status: res.data.status,
        id: res.data.application.id,
      });
    });
  };

  return (
    <>
   <div class="content-wrapper pt-1">
        {/* Content */}
        <div className="content container-fluid">
          {/* Page Header */}

          {/* End Page Header */}
          <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-0">
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                {/* Header */}
                <div className="card-header">
                  <h5 className="card-header-title">Application Detail</h5>
                </div>
                {/* End Header */}
                {/* Body */}
                <div className="card-body">
                  {/* End Form */}
                  <div className="row">
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>First Name : </b> {application.first_name}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Surname : </b> {application.surname}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Date : </b> {application.date}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Phone : </b> {application.phone}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Email : </b> {application.email}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Nationality : </b> {application.nationality}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Residing Country : </b>{" "}
                        {application.residing_country}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Visa Partner Agency : </b>{" "}
                        {application.visa_partner_agency}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Client Agency : </b> {application.client_agency}
                      </div>
                      {/* End Form */}
                    </div>
                    <div className="col-sm-4">
                      {/* Form */}
                      <div className="mb-4">
                        <b>Status : </b> {application.status}
                      </div>
                      {/* End Form */}
                    </div>
                
                  </div>
                  {/* End Quill */}
                </div>
                {/* Body */}
              </div>
              <div className="card mb-3 mb-lg-5">
                {/* Header */}
                <div className="card-header card-header-content-md-between">
                  <div className="mb-2 mb-md-0">
                    <form>
                      {/* Search */}
                      <div className="input-group input-group-merge input-group-flush">
                        <h5 className="card-header-title">All Documents</h5>
                      </div>
                      {/* End Search */}
                    </form>
                  </div>
                  <div className="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2">
                    {/* Datatable Info */}
                    <div className="dropdown">
                      {/* /admin/application/:id/fileUpload */}
                      <Link
                        to={`/admin/application/${application.id}/fileUpload`}
                        type="button"
                        className="btn btn-white btn-sm w-100"
                      >
                        <i className="bi-filter me-1" /> Upload File
                      </Link>
                    </div>
                    {/* End Dropdown */}
                  </div>
                </div>
          <div className="card-body">
             {/* Gallery */}
              <h6>Client</h6>
              <div id="fancyboxGallery" className="js-fancybox row justify-content-sm-center gx-3">
               {DataListClient && DataListClient.map((item,index)=>(
                    <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                    {/* Card */}
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row col-divider text-center">
                          <a href={item.document} target="_blank">
                        <img className="card-img-top" style={{width: 50}} src={fileImage} alt="file" />
                         </a>
                          <div className="col">
                            Name:{item.name} <br />
                            Date:{item.expiry_date} <br />
                            <input className="custom-control-input" type="checkbox" checked={item.show_document == 1}  id={`customCheckbox${index}`}  onClick={()=>{hideChange(item.id)}}  />
                            <label htmlFor={`customCheckbox${index}`} className="custom-control-label">Show</label>
                          </div>
                          {/* End Col */}
                        </div>
                        {/* End Row */}
                      </div>
                      {/* End Col */}
                    </div>
                    {/* End Card */}
                  </div>
                ))}
               </div>

                  <h6>Visa Partner</h6>
                <div id="fancyboxGallery" className="js-fancybox row justify-content-sm-center gx-3">
               {DataListVisa && DataListVisa.map((item,index)=>(
                    <div className="col-6 col-sm-4 col-md-3 mb-3 mb-lg-5">
                    {/* Card */}
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row col-divider text-center">
                          <a href={item.document} target="_blank">
                        <img className="card-img-top" style={{width: 50}} src={fileImage} alt="file" />
                         </a>
                          <div className="col">
                            Name:{item.name} <br />
                            Date:{item.expiry_date} <br />
                            <input className="custom-control-input" type="checkbox"  id={`customCheckboxx${index}`}  checked={item.show_document == 1}  onClick={()=>{hideChange(item.id)}}  />
                            <label htmlFor={`customCheckboxx${index}`} className="custom-control-label">Show</label>
                          </div>
                          {/* End Col */}
                        </div>
                        {/* End Row */}
                      </div>
                      {/* End Col */}
                    </div>
                    {/* End Card */}
                  </div>
                ))}
              </div>
          </div>
                {/* Body */}
         </div>

              {/* End Card */}
        </div>
          </div>
          {/* End Row */}
        </div>
      </div>
    </>
  );
}

export default ShowApplication;
