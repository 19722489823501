import React from "react";

function VisaPatnerHome() {
    return (
     <>
  <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col">
          <h4 className="page-header-title">Dashboard </h4>
        </div>
      </div>
      {/* End Row */}
    </div>
    <div className="row">
      <div className="col-sm-6 col-lg-6 mb-3 mb-lg-5">
        {/* Card */}
        <a className="card card-hover-shadow h-100" href="#">
          <div className="card-body">
            <h6 className="card-subtitle">Application</h6>
            <div className="row align-items-center gx-2 mb-1">
              <div className="col-6">
                <h2 className="card-title text-inherit">34</h2>
              </div>
              {/* End Col */}
              <div className="col-6">
                {/* Chart */}
                <div className="chartjs-custom" style={{height: '3rem'}}>
                </div>
                {/* End Chart */}
              </div>
              {/* End Col */}
            </div>
          </div>
        </a>
        {/* End Card */}
      </div>
    </div>
  </div>
</div>
     </>
    );
  }
  
export default VisaPatnerHome;
  