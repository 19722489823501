import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function AddStatus() {
  const nav = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);
  const [status, SetStatus] = useState({
    name: "",
  });

  const inputControl = (e) => {
    e.persist();
    SetStatus({ ...status, [e.target.name]: e.target.value });
  };

  const saveData = (e) => {
    e.preventDefault();
    const data = {
      name: status.name,
    };
    console.log(data);

    Http.post("/status", data)
      .then((res) => {
        console.log(res);
        
        nav("/admin/statusList", {
          state: {
            mgs: 1,
          },
        });
      })
      .catch((error) => {
        if (error.response.status == 400) {
          console.log(error.response);
          setErrorMessage(error.response.data.message);
        }
        return error;
      });
  };

  return (
    <>
         <div class="content-wrapper pt-1">
        {/* Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="p-3">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h4 className="page-header-title">Add Status</h4>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}
          <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-0">
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                {/* Header */}
                <div className="card-header">
                  <h6 className="card-header-title">Status Information</h6>
                </div>
                {/* End Header */}
                {/* Body */}
                <div className="card-body">
                  {/* End Form */}
                  <form onSubmit={saveData}>
                    <div className="row">
                      <div className="col-sm-6">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            Status{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={inputControl}
                            name="name"
                            required
                            id="productNameLabel"
                            placeholder="Enter Status"
                          />
                          <span className="text-danger">
                            {errorMessage.name}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      <input type="hidden" name="name" value='1' />
                      <div className="col-sm-12">
                        {/* Form */}
                        <button type="submit" class="btn btn-primary">
                          Save
                        </button>
                        {/* End Form */}
                      </div>
                    </div>
                  </form>
                  {/* End Quill */}
                </div>
                {/* Body */}
              </div>

              {/* End Card */}
            </div>
          </div>
          {/* End Row */}
        </div>

        {/* End Footer */}
      </div>
    </>
  );
}

export default AddStatus;
