import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function AddBanner() {
  const nav = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);
  const [banner, setBanner] = useState({
    name: "",
    photo: "",
    order: "",
    banner_type: "",
    status: "",
  });

  const inputControl=(e)=>{
    // setFile(e.target.value)
    if (e.target.type === 'file') {
        setBanner({...banner, photo: e.target.files[0] });
      } else {
        setBanner({...banner, [e.target.name]: e.target.value });
      }
      console.log(banner);
    
  }

  const saveData = (e) => {
    e.preventDefault();
    const formData=new FormData();
    // formData.append('national_id_attachment', file);
    formData.append('name', banner.name);
    formData.append('photo', banner.photo);
    formData.append('order', banner.order);
    formData.append('banner_type', banner.banner_type);
    formData.append('status', banner.status);

    const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

    Http.post("/banner", formData,config)
      .then((res) => {
        nav("/admin/BannerList", {
          state: {
            mgs: 1,
          },
        });
      })
      .catch((error) => {
        if (error.response.status == 400) {
          console.log(error.response);
          setErrorMessage(error.response.data.message);
        }
        return error;
      });
  };

  return (
    <>
      <div class="content-wrapper pt-1">
        {/* Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="p-3">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h4 className="page-header-title">Add Banner</h4>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}
          <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-0">
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                {/* Header */}
                <div className="card-header">
                  <h6 className="card-header-title">Banner information</h6>
                </div>
                {/* End Header */}
                {/* Body */}
                <div className="card-body">
                  {/* End Form */}
                  <form onSubmit={saveData}>
                    <div className="row">
                      <div className="col-sm-4">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            File{" "}
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={inputControl}
                            name="photo"
                            required
                            id="productNameLabel"
                            placeholder="Enter "
                          />
                          <span className="text-danger">
                            {errorMessage.photo}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      <div className="col-sm-4">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={inputControl}
                            name="name"
                            required
                            id="productNameLabel"
                            placeholder="Enter Name"
                          />
                          <span className="text-danger">
                            {errorMessage.name}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      <div className="col-sm-4">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            Order{" "}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={inputControl}
                            name="order"
                            min='0'
                            required
                            id="productNameLabel"
                            placeholder="Enter Order"
                          />
                          <span className="text-danger">
                            {errorMessage.order}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      {/* <input type="hidden" name="status" value="1" /> */}
                      <div className="col-sm-4">
                 
                 <div className="mb-4">
                   <label htmlFor="SKULabel" className="form-label">
                     Banner Type
                   </label>
                   <select  onChange={inputControl}  name="banner_type"
                     class="form-control"
                     aria-label="Default select example">
                        <option value="1">Select </option>
                     <option value="1">Banner</option>
                     <option value="0">Sponser</option>
                   </select>
                   <span className="text-danger">
                     {errorMessage.status}
                   </span>
                 </div>
               </div>
                      <div className="col-sm-4">
                 
                        <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            Status
                          </label>
                          <select  onChange={inputControl}  name="status"
                            class="form-control"
                            aria-label="Default select example">
                               <option value="1">Select </option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        {/* Form */}
                        <button type="submit" class="btn btn-primary">
                          Save
                        </button>
                        {/* End Form */}
                      </div>
                    </div>
                  </form>
                  {/* End Quill */}
                </div>
                {/* Body */}
              </div>

              {/* End Card */}
            </div>
          </div>
          {/* End Row */}
        </div>

        {/* End Footer */}
      </div>
    </>
  );
}

export default AddBanner;
