import React from "react";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function AddApplication() {
  const nav=useNavigate();
  const [errorMessage,setErrorMessage]=useState([]);

  const [country, setCountry] = useState([]);
  const [client, setClient] = useState([]);
  const [vizaPatner, setVizaPatner] = useState([]);
  const [status, setStatus] = useState([]);
  const [submitData, setSubmitData] = useState([]);
  const [image, setImage] = useState([]);
  const [application, setApplication] = useState(({
    first_name:'',
    surname:'',
    date:'',
    phone:'',
    email:'',
    nationality:'',
    residing_country:'',
    applying_country:'',
    client_agency:'',
    visa_partner_agency:'',
    status:'',
  }));

  useEffect(()=>{
    fetchData();
  },[])
  const [fileData, setFileData] = useState(({
    name:'',
    date:'',
    file:''
  }));


  const fetchData =()=>{
    Http.get('/Application/create').then(res=>{
      console.log(res);
      setCountry(res.data.country);
      setClient(res.data.clientAgency);
      setVizaPatner(res.data.visaPatner);
      setStatus(res.data.status);

    })
  }

  const inputControl=(e)=>{
    e.persist();
    setApplication({...application,[e.target.name]:e.target.value});
    console.log(application);
  }

  const saveData=(e)=>{
    e.preventDefault();
    const data={
      first_name:application.first_name,
      surname:application.surname,
      date:application.date,
      phone:application.phone,
      email:application.email,
      nationality:application.nationality,
      residing_country:application.residing_country,
      applying_country:application.applying_country,
      client_agency:application.client_agency,
      visa_partner_agency:application.visa_partner_agency,
      status:application.status,
    }
    const formData=new FormData();
    formData.append('first_name', application.first_name);
    formData.append('surname', application.surname);
    formData.append('date', application.date);
    formData.append('phone', application.phone);
    formData.append('email', application.email);
    formData.append('nationality', application.nationality);
    formData.append('residing_country',application.residing_country);
    formData.append('applying_country', application.applying_country);
    formData.append('visa_partner_agency',application.visa_partner_agency);
    formData.append('client_agency',application.client_agency);
    formData.append('status', application.status);
    // formData.append('status', application.status);

    image.forEach((img, index) => {
      formData.append('images[]', img); // <--- Change here
    });
  
    // Append fileData to formData
    submitData.forEach((file, index) => {
      formData.append(`fileData`, file); // <--- Change here
    });

    console.log(formData);
    

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    Http.post('/Application',formData,config).then(res=>{
      console.log(res);
      
      nav('/admin/applicationList',{
        state: {
          mgs: 1,
        },
      });

    }).catch((error) => {
     if(error.response.status==400){
      console.log(error.response);
      setErrorMessage(error.response.data.message);
     }
     return error;
  })
}
    return (
     <>
   <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">Add Application</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Application Information</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <form onSubmit={saveData}>
            <div className="row">
            <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">First Name </label>
              <input type="text" className="form-control"  onChange={inputControl}  name="first_name" id="productNameLabel" placeholder="Enter name" required />
              <span className="text-danger">{errorMessage.first_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Surname </label>
              <input type="text" className="form-control"  onChange={inputControl} name="surname" id="productNameLabel" placeholder="Enter surname" required />
              <span className="text-danger">{errorMessage.surname}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel"  className="form-label">Date </label>
              <input type="date" className="form-control"  onChange={inputControl} name="date"id="productNameLabel" required />
              <span className="text-danger">{errorMessage.date}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel"   className="form-label">Email </label>
              <input type="email" className="form-control" onChange={inputControl} name="email" id="productNameLabel" placeholder="Enter Email"  required/>
              <span className="text-danger">{errorMessage.email}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Phone </label>
              <input type="number" className="form-control"  onChange={inputControl} name="phone"  id="productNameLabel" placeholder="Enter phone" required />
              <span className="text-danger">{errorMessage.phone}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Nationality
                          </label>
                          <select  onChange={inputControl}  name="nationality" required
                           className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {country && country.map((item,index)=>(
                              <option value={item.id}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.nationality}
                          </span>
                        </div>

                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Residing Country
                          </label>
                          <select  onChange={inputControl}  name="residing_country" required
                            className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {country && country.map((item,index)=>(
                              <option value={item.id}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.residing_country}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Applying Country
                          </label>
                          <select  onChange={inputControl}  name="applying_country" required
                           className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {country && country.map((item,index)=>(
                              <option value={item.id}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.applying_country}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Client Agency
                          </label>
                          <select  onChange={inputControl}  name="client_agency" required
                           className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {client && client.map((item,index)=>(
                              <option value={item.id}>{item.agency_name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.client_agency}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Visa Partner Agency 
                          </label>
                          <select  onChange={inputControl}  name="visa_partner_agency" required
                            className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {vizaPatner && vizaPatner.map((item,index)=>(
                              <option value={item.id}>{item.agency_name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.visa_partner_agency}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            Status
                          </label>
                          <select  onChange={inputControl}  name="status" required
                            className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {status && status.map((item,index)=>(
                              <option value={item.id}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-12">
                {/* Form */}
                <button type="submit" class="btn btn-primary">Save</button>
                {/* End Form */}
              </div>
            </div>
            </form>
            {/* End Quill */}
          </div>
          
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>

    </div>
    
    {/* End Row */}
 
  </div>
</div>


     </>
    );
  }
export default AddApplication;
  