import React from "react";
import axios from "axios";
import Http from "../../BaseUrl/Http";
import { useEffect,useState } from "react";
import { Link,useLocation} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Component/Loader";

function ExpireApplicationAdmin() {
  
  const [LoaderShow, setLoaderShow] = useState(true);
  const { state } = useLocation();
  const [searcData, setSearchData] = useState({
    name: "",
    status: "",
  });

  const [pagination, setPagination] = useState({
    total: "",
    show: "",
    links: "",
  });

  function submitSearch(e){
    const data = {
      name: searcData.name,
      status: searcData.status,
    };

    e.preventDefault();
    const queryParams = new URLSearchParams(data);
    const url = `/expireDocument?${queryParams.toString()}`;
    Http.get(url).then(res=>{
      setDataList(res.data.application.data);
      console.log(res);
      setPagination({
        total: res.data.application.total,
        show: res.data.application.to,
        links: res.data.application.links,
      });
      // setLoaderShow(false)
    })
  }
  const inputControl = (e) => {
    e.persist();
    setSearchData({ ...searcData, [e.target.name]: e.target.value });
    console.log(searcData);
    
  };
  const [DataList, setDataList] = useState([]);
  useEffect(()=>{
    fetchData();
    setLoaderShow(false)
    if(state?.mgs==1){
      toast.success("Data Add Successfully");
    }
    if(state?.mgs==2){
      toast.success("Data Update Successfully");
    }
  },[])
  const fetchData =()=>{
    Http.get('/expireDocument').then(res=>{
      console.log(res);
      setPagination({
        total: res.data.application.total,
        show: res.data.application.to,
        links: res.data.application.links,
      });
      
      setDataList(res.data.application.data);
    })
  }
  
  const paginationFun = (url) => {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res);
        console.log("pass");
        setPagination({
          total: res.data.application.total,
          show: res.data.application.to,
          links: res.data.application.links,
        });
        setDataList(res.data.application.data);
        setLoaderShow(false);
      });
  };

  const deleteFun=(id)=>{
    console.log(id);
    Http.delete(`/Application/${id}`).then(res=>{
      fetchData();
      toast.success("Data Delete Successfully");
    }).catch((error) => {
      return error;
   });
  }
    return (
     <>
     {
      LoaderShow ?(
        <Loading/>
      ):(
        <div className="content-wrapper">
              <div className="row align-items-center ps-4">
        <div className="col-sm mb-2 mb-sm-0">
          {/* <h4 className="page-header-title p-2">Expired Document Application</h4> */}
        </div>
        {/* End Col */}
      </div>
       <div className="card m-3">
          <ToastContainer/>
    <div className="card-header">
    <div className="card-title">
    <h4 className="page-header-title">Expired Document Application</h4>
    </div>
  </div>

  {/* /.card-header */}
  <div className="card-body table-responsive p-0">
    <table className="table table-head-fixed text-nowrap">
    <thead className="thead-light">
                 <tr role="row">
                 <th scope="col"  >No</th>
                   <th scope="col" >First Name</th>
                   <th scope="col" >Surname</th>
                   <th scope="col" >Phone</th>
                   <th scope="col" >Date</th>
                   <th scope="col" >Action</th>
                   </tr>
               </thead>
               <tbody>
                
               {DataList && DataList.map((item,index)=>(
                            <tr key={index}>
                             
                            <td>{++index}</td>
                            <td>{item.first_name}</td>
                            <td>{item.surname}</td>
                            <td>{item.phone}</td>
                            <td>{item.date}</td>
                            <td>
                              <a href={item.document} target="blank"> <i class="bi-eye"></i></a>
                            </td>
                          </tr>
                       ))}
                 </tbody>
    </table>
    <div className="card-footer">
              <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                <div className="col-sm mb-2 mb-sm-0">
                  <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                    <span className="me-2">Showing:</span>
                    {/* Select */}
                    <div className="tom-select-custom p-1">
                      {pagination.show}
                    </div>
                    {/* End Select */}
                    <span className="text-secondary me-2">of</span>
                    {/* Pagination Quantity */}
                    <span id="datatableWithPaginationInfoTotalQty">
                      {pagination.total}
                    </span>
                  </div>
                </div>
                {/* End Col */}
                <div className="col-sm-auto">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    {/* Pagination */}
                    <nav
                      id="datatablePagination"
                      aria-label="Activity pagination"
                    >
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="datatable_paginate"
                      >
                        <ul
                          id="datatable_pagination"
                          className="pagination datatable-custom-pagination"
                        >
                          {pagination.links &&
                            pagination.links.map((item, index) => (
                              <li
                                key={index}
                                className={
                                  "paginate_item page-item " +
                                  (item.active ? "active" : "")
                                }
                              >
                                {item.label.match(/^\d+$/) && (
                                  <div
                                    onClick={() => paginationFun(item.url)}
                                    className="paginate_button previous page-link"
                                  >
                                    <span aria-hidden="true">{item.label}</span>
                                  </div>
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
  </div>
  {/* /.card-body */}
          </div>
       
       </div>
      )
     }
     </>
    );
  }
  
export default ExpireApplicationAdmin;
  