import React from "react";
import { useState,useEffect } from "react";
import { useNavigate,useParams} from "react-router-dom";
import Http from "../../BaseUrl/Http";

function ClientApplicationEdit() {
  const nav=useNavigate();
  let {id}=useParams();
  const [errorMessage,setErrorMessage]=useState([]);

  const [country, setCountry] = useState([]);
  const [client, setClient] = useState([]);
  const [vizaPatner, setVizaPatner] = useState([]);
  const [application, setApplication] = useState(({
    first_name:'',
    surname:'',
    date:'',
    phone:'',
    email:'',
    nationality:'',
    residing_country:'',
    applying_country:'',
    client_agency:localStorage.getItem("clientId") ,
    visa_partner_agency:'',
    status:'',
  }));

  useEffect(()=>{
    fetchData();
  },[])

  const fetchData =()=>{
    Http.get(`/Application/${id}/edit`).then(res=>{
        setCountry(res.data.country);
        setClient(res.data.clientAgency);
        setVizaPatner(res.data.visaPatner);
        setApplication({
            first_name:res.data.application.first_name,
            surname:res.data.application.surname,
            date:res.data.application.date,
            phone:res.data.application.phone,
            email:res.data.application.email,
            nationality:res.data.application.nationality,
            residing_country:res.data.application.residing_country,
            applying_country:res.data.application.applying_country,
            client_agency:res.data.application.client_agency,
            visa_partner_agency:res.data.application.visa_partner_agency,
            status:res.data.application.status,
          })
    })
  }

  const inputControl=(e)=>{
    e.persist();
    setApplication({...application,[e.target.name]:e.target.value});
    console.log(application);
    
  }

  const saveData=(e)=>{
    e.preventDefault();
    const data={
      first_name:application.first_name,
      surname:application.surname,
      date:application.date,
      phone:application.phone,
      email:application.email,
      nationality:application.nationality,
      residing_country:application.residing_country,
      applying_country:application.applying_country,
      client_agency:application.client_agency,
      visa_partner_agency:application.visa_partner_agency,
      status:application.status,
    }

    Http.put(`/Application/${id}`,data).then(res=>{
      console.log(res);
      
      nav('/client/home/list',{
        state: {
          mgs: 1,
        },
      });

    }).catch((error) => {
     if(error.response.status==400){
      console.log(error.response);
      setErrorMessage(error.response.data.message);
     }
     return error;
  })
}
    return (
     <>
   <div class="content-wrapper pt-1">
  {/* Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="p-3">
      <div className="row align-items-center">
        <div className="col-sm mb-2 mb-sm-0">
          <h4 className="page-header-title">Update Application</h4>
        </div>
        {/* End Col */}
      </div>
      {/* End Row */}
    </div>
    {/* End Page Header */}
    <div className="row">
      <div className="col-lg-12 mb-3 mb-lg-0">
        {/* Card */}
        <div className="card mb-3 mb-lg-5">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Application information</h5>
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
            {/* End Form */}
            <form onSubmit={saveData}>
            <div className="row">
            <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">First Name </label>
              <input type="text" className="form-control"  onChange={inputControl} value={application.first_name}  name="first_name" id="productNameLabel" placeholder="Enter name" required />
              <span className="text-danger">{errorMessage.first_name}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Surname </label>
              <input type="text" className="form-control"  onChange={inputControl}  value={application.surname} name="surname" id="productNameLabel" placeholder="Enter surname" required />
              <span className="text-danger">{errorMessage.surname}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel"  className="form-label">Date </label>
              <input type="date" className="form-control"  value={application.date} onChange={inputControl} name="date" id="productNameLabel" required />
              <span className="text-danger">{errorMessage.date}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel"   className="form-label">Email </label>
              <input type="email" className="form-control" onChange={inputControl}  value={application.email} name="email" id="productNameLabel" placeholder="Enter Email" required/>
              <span className="text-danger">{errorMessage.email}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                <label htmlFor="productNameLabel" className="form-label">Phone </label>
              <input type="number" className="form-control"  onChange={inputControl}  value={application.phone} name="phone"  id="productNameLabel" placeholder="Enter phone" required/>
              <span className="text-danger">{errorMessage.phone}</span>
                </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Nationality
                          </label>
                          <select  onChange={inputControl}  name="nationality" required
                            className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {country && country.map((item,index)=>(
                              <option value={item.id} selected={item.id == application.nationality ? "selected" : ""}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.nationality}
                          </span>
                        </div>

                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Residing Country
                          </label>
                          <select  onChange={inputControl} required
                           name="residing_country"
                            className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {country && country.map((item,index)=>(
                              <option value={item.id} selected={item.id == application.residing_country ? "selected" : ""}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.residing_country}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Applying Country
                          </label>
                          <select  onChange={inputControl}  name="applying_country" required
                            className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {country && country.map((item,index)=>(
                              <option value={item.id} selected={item.id == application.applying_country ? "selected" : ""}>{item.name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.applying_country}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
                <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                          Client Agency
                          </label>
                          <select  onChange={inputControl}  name="client_agency" required
                            className="form-control"
                            aria-label="Default select example">
                               <option value=" ">Select</option>
                               {client && client.map((item,index)=>(
                              <option value={item.id} selected={item.id == application.client_agency ? "selected" : ""}>{item.agency_name}</option>
                              ))}
                          </select>
                          <span className="text-danger">
                            {errorMessage.client_agency}
                          </span>
                        </div>
                {/* End Form */}
              </div>
              <div className="col-sm-6">
                {/* Form */}
         
                {/* End Form */}
              </div>
              <div className="col-sm-12">
                {/* Form */}
                <button type="submit" class="btn btn-primary">Save</button>
                {/* End Form */}
              </div>
            </div>
            </form>
            {/* End Quill */}
          </div>
          {/* Body */}
        </div>
 
        {/* End Card */}
      </div>
    </div>
    {/* End Row */}
 
  </div>
</div>
     </>
    );
  }
export default ClientApplicationEdit;
  