import React from "react";
import { Link } from "react-router-dom";
import logoo from "../Image/VisaSure_Logo_rect.png"

function SideBar() {
    return (
     <>
    

<aside className="main-sidebar sidebar-dark-primary elevation-4">
  {/* Brand Logo */}
  <a href="#" className="brand-link">
<img src={logoo} style={{width: '45%'}} alt="AdminLTE Logo" />

    {/* <span className="brand-text font-weight-light">AdminLTE 3</span> */}
  </a>
  {/* Sidebar */}
  <div className="sidebar">
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
        {    localStorage.getItem("type") =='0' &&
            <>
              <div class="nav-item">
                <Link class="nav-link" to={"/admin/statusList"} data-placement="left">
                <i class="bi-calendar-week nav-icon"></i>
                  <span class="nav-link-title">Status</span>
                </Link>
              </div>
              <div class="nav-item">
                <Link class="nav-link" to={"/admin/bannerList"} data-placement="left">
                <i class="bi bi-file-code"></i>
                  <span class="nav-link-title">Banner</span>
                </Link>
              </div>
                <div class="nav-item">
                <Link class="nav-link" to={"/admin/CountryList"} data-placement="left">
                <i class="bi bi-flag"></i>
                  <span class="nav-link-title">Country</span>
                </Link>
              </div>
              <div class="nav-item">
                <Link class="nav-link" to={"/admin/staffList"} data-placement="left">
                <i class="bi-people nav-icon"></i>
                  <span class="nav-link-title">Staff</span>
                </Link>
              </div>
              <div class="nav-item">
                <Link class="nav-link" to={"/admin/clientList"} data-placement="left">
                <i class="bi-person-badge nav-icon"></i>
                  <span class="nav-link-title">Client</span>
                </Link>
              </div>
              <div class="nav-item">
                <Link class="nav-link" to={"/admin/visaPatnerList"} data-placement="left">
                <i class="bi-briefcase nav-icon"></i>
                  <span class="nav-link-title">Visa Partner</span>
                </Link>
              </div>
              <div class="nav-item">
                <Link class="nav-link" to={"/admin/applicationList"} data-placement="left">
                <i class="bi-stickies nav-icon"></i>
                  <span class="nav-link-title">Application</span>
                </Link>
              </div>
              <div class="nav-item">
                <Link class="nav-link" to={"/admin/expireApplication"} data-placement="left">
                <i class="bi-stickies nav-icon"></i>
                  <span class="nav-link-title">Expire Document</span>
                </Link>
              </div>
              </>
            }
             {    localStorage.getItem("type") =='2' &&
            <>
                <div class="nav-item">
                <Link class="nav-link" to={"/staff/home/"}>
                  <i class="bi-kanban nav-icon"></i>
                  <span class="nav-link-title">Dashboard</span>
                </Link>
                </div>
                <div class="nav-item">
                <Link class="nav-link" to={'/admin/applicationList'} >
                  <i class="bi-kanban nav-icon"></i>
                  <span class="nav-link-title">Application</span>
                </Link>
                </div>
               
                <div class="nav-item">
                <Link class="nav-link" to={"/admin/expireApplication"} >
                <i class="bi-stickies nav-icon"></i>
                  <span class="nav-link-title">Expire Document</span>
                </Link>
                </div>
         
         
              </>
            }
              {    localStorage.getItem("type") =='3' &&
            <>
                <div class="nav-item">
                <Link class="nav-link" to={"/Client/home/"} data-placement="left">
                  <i class="bi-kanban nav-icon"></i>
                  <span class="nav-link-title">Dashboard</span>
                </Link>
                </div>
                <div class="nav-item">
                <Link class="nav-link" to={'/client/home/list'} data-placement="left">
                  <i class="bi-kanban nav-icon"></i>
                  <span class="nav-link-title">Application</span>
                </Link>
                </div>
                   <div class="nav-item">
                <Link class="nav-link" to={'/staff/home/DocumentExpire'} data-placement="left">
                  <i class="bi-kanban nav-icon"></i>
                  <span class="nav-link-title">Expire Document</span>
                </Link>
              </div>
              </>
            }
             {    localStorage.getItem("type") =='4' &&
            <>
                <div class="nav-item">
                <Link class="nav-link" to={"/visaPatner/home"} data-placement="left">
                  <i class="bi-kanban nav-icon"></i>
                  <span class="nav-link-title">Dashboard</span>
                </Link>
                </div>   <div class="nav-item">
                <Link class="nav-link" to={'/visaPatner/home/list'} data-placement="left">
                  <i class="bi-kanban nav-icon"></i>
                  <span class="nav-link-title">Application</span>
                </Link>
              </div>
              <div class="nav-item">
                <Link class="nav-link" to={"/visaPatner/home/applicationExpire"} >
                <i class="bi-stickies nav-icon"></i>
                  <span class="nav-link-title">Expire Document</span>
                </Link>
                </div>
              </>
            }
      </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}
</aside>

     </>
    );
  }
  
export default SideBar;
  