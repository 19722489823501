import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../BaseUrl/Http";

function EditBanner() {
  let { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);
  const [banner, setBanner] = useState({
    name: "",
    photo: "",
    order: "",
    banner_type: "",
    status: "",
  });

  const fetchData = () => {
    Http.get(`/banner/${id}/edit`).then((res) => {
      console.log(res);

      setBanner({
        name: res.data.banner.name,
        photo: res.data.banner.photo,
        order: res.data.banner.order,
        banner_type: res.data.banner.banner_type,
        status: res.data.banner.status,
      });
    });
  };

  const nav = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);

  const inputControl = (e) => {
    e.persist();
    setBanner({ ...banner, [e.target.name]: e.target.value });
    console.log(banner);
    
  };

  const updateData = (e) => {
    e.preventDefault();
    const formData=new FormData();
    // formData.append('national_id_attachment', file);
    formData.append("_method", "PUT");
    formData.append('name', banner.name);
    formData.append('photo', banner.photo);
    formData.append('order', banner.order);
    formData.append('banner_type', banner.banner_type);
    formData.append('status', banner.status);

    const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

    Http.post(`/banner/${id}`, formData,config)
      .then((res) => {
        nav("/admin/BannerList", {
          state: {
            mgs: 2,
          },
        });
      })
      .catch((error) => {
        if (error.response.status == 400) {
          console.log(error.response);
          setErrorMessage(error.response.data.message);
        }
        return error;
      });
  };
  return (
    <>
      <div class="content-wrapper pt-1">
        {/* Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="p-3">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h4 className="page-header-title">Update Banner</h4>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}
          <div className="row">
            <div className="col-lg-12 mb-3 mb-lg-0">
              {/* Card */}
              <div className="card mb-3 mb-lg-5">
                {/* Header */}
                <div className="card-header">
                  <h5 className="card-header-title">Banner information</h5>
                </div>
                <div className="card-body">
                  {/* End Form */}
                  <form onSubmit={updateData}>
                    <div className="row">
                      <div className="col-sm-4">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            File{" "}
                          </label>
                          <input
                          accept="image/*"
                            type="file"
                            className="form-control"
                            onChange={inputControl}
                            name="photo"
                            id="productNameLabel"
                            placeholder="Enter "
                          />
                          <span className="text-danger">
                            {errorMessage.photo}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      <div className="col-sm-4">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            Name{" "}
                          </label>
                          <input
                            type="text"
                            value={banner.name}
                            className="form-control"
                            onChange={inputControl}
                            name="name"
                            required
                            id="productNameLabel"
                            placeholder="Enter Name"
                          />
                          <span className="text-danger">
                            {errorMessage.name}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      <div className="col-sm-4">
                        {/* Form */}
                        <div className="mb-4">
                          <label
                            htmlFor="productNameLabel"
                            className="form-label"
                          >
                            Order{" "}
                          </label>
                          <input
                           min="0"
                            type="text"
                            value={banner.order}
                            className="form-control"
                            onChange={inputControl}
                            name="order"
                            required
                            id="productNameLabel"
                            placeholder="Enter Order"
                          />
                          <span className="text-danger">
                            {errorMessage.order}
                          </span>
                        </div>
                        {/* End Form */}
                      </div>
                      {/* <input type="hidden" name="status" value="1" /> */}
                      <div className="col-sm-4">
                        <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            Banner Type
                          </label>
                          <select
                            onChange={inputControl}
                            name="banner_type"
                            class="form-control"
                            aria-label="Default select example"
                          >
                            <option value="1">Select </option>
                            <option
                              value="1"
                              selected={
                                1 == banner.banner_type ? "selected" : ""
                              }
                            >
                              Banner
                            </option>
                            <option
                              value="0"
                              selected={
                                0 == banner.banner_type ? "selected" : ""
                              }
                            >
                              Sponser
                            </option>
                          </select>
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="mb-4">
                          <label htmlFor="SKULabel" className="form-label">
                            Status
                          </label>
                          <select
                            onChange={inputControl}
                            name="status"
                            class="form-control"
                            aria-label="Default select example"
                          >
                            <option value="1">Select </option>
                            <option
                              value="1"
                              selected={1 == banner.status ? "selected" : ""}
                            >
                              Active
                            </option>
                            <option
                              value="0"
                              selected={0 == banner.status ? "selected" : ""}
                            >
                              Inactive
                            </option>
                          </select>
                          <span className="text-danger">
                            {errorMessage.status}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        {/* Form */}
                        <button type="submit" class="btn btn-primary">
                          Save
                        </button>
                        {/* End Form */}
                      </div>
                    </div>
                  </form>
                  {/* End Quill */}
                </div>
                {/* Body */}
              </div>

              {/* End Card */}
            </div>
          </div>
          {/* End Row */}
        </div>
        {/* End Footer */}
      </div>
    </>
  );
}

export default EditBanner;
